import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby-plugin-intl"
import heart from "../../src/images/images/heart.png"
import xSign from "../../src/images/images/x.png"
import { localized } from "../utils/localized"

const Quiz = props => {
  return (
    <Layout>
      <SEO title="Quiz" className="text-center" />
      <section className="section section--instructor-list overflow-x-hidden">
        <div className="section__inner align-items-center">
          <h2 className=" margin-bottom-md margin-top-lg">Questions</h2>
          <div className="grid grid-gap-md grid-gap-xxl@md">
            <QuizCard quiz={props.data.quiz.edges[0].node} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

const QuizCard = ({ quiz }) => {
  const { questionList, resultList, resultGreater, standardScore } = quiz
  let [index, setIndex] = useState(0)
  let [score, setScore] = useState(0)
  let [testOver, setTestOver] = useState(false)
  let [result, setResult] = useState(null)
  let [animation, setAnimation] = useState("")
  let [touchStart, setTouchStart] = useState(0)
  let [touchEnd, setTouchEnd] = useState(0)

  const selectAnswer = userAnswer => {
    if (userAnswer === questionList[index].answer) {
      setScore(score + questionList[index].point)
    }

    if (index >= questionList.length - 1) {
      if (score >= standardScore) {
        setResult(resultGreater === "ml" ? 1 : 0)
      } else {
        setResult(resultGreater === "ml" ? 0 : 1)
      }
      setTestOver(true)
    }
    setAnimation(userAnswer === "yes" ? "quiz-card-yay" : "quiz-card-nope")
    setIndex(index + 1)
  }

  const handleTouchStart = e => {
    setTouchStart(e.targetTouches[0].clientX)
  }
  const handleTouchMove = e => {
    setTouchEnd(e.targetTouches[0].clientX)
  }
  const handleTouchEnd = e => {
    if (touchStart - touchEnd > 180) {
      selectAnswer("no")
    }

    if (touchStart - touchEnd < -180) {
      selectAnswer("yes")
    }
  }

  const afterAnimation = () => {
    setAnimation("")
  }

  return (
    <div>
      {testOver && animation === "" ? (
        <div className="card card--heightAuto card--shadow card--border-gradient result-box mb-2">
          <div className="result-content-box">
            <h2 className="card__title text-center result-content">
              {localized(resultList[result].content)}
            </h2>
            <div className="learn-more-box">
              <Link to={resultList[result].slug} className="learn-more-content">
                Learn More about {resultList[result].title} Courses
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={`card card--heightAuto card--shadow card--border-gradient quiz-card ${animation}`}
            onAnimationEnd={afterAnimation}
            onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
            onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
            onTouchEnd={handleTouchEnd}
          >
            <h2 className="card__title text-center quiz-card-title">
              {questionList && animation !== ""
                ? localized(questionList[index - 1].content)
                : localized(questionList[index].content)}
            </h2>
          </div>
          <div className="answer-button-area text-center">
            <AnswerButton type="no" sign={xSign} selectAnswer={selectAnswer} />
            <AnswerButton type="yes" sign={heart} selectAnswer={selectAnswer} />
          </div>
        </div>
      )}
    </div>
  )
}
const AnswerButton = ({ type, sign, selectAnswer }) => {
  return (
    <div className="answer-button mr-2" onClick={() => selectAnswer(type)}>
      <img src={sign} className="answer-button-img" />
    </div>
  )
}
export const pageQuery = graphql`
  query {
    quiz: allSanityQuiz {
      edges {
        node {
          id
          questionList {
            content {
              en
              vi
            }
            answer
            point
          }
          resultList {
            title
            content {
              en
              vi
            }
            slug
          }
          resultGreater
          standardScore
        }
      }
    }
  }
`

export default Quiz
